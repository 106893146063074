<template>
  <label
    class="counter"
    :class="{
      'counter--disabled' : disableInputEdit
    }"
  >
    <input
      v-if="isInputEnabled"
      :type="inputType"
      inputmode="numeric"
      class="counter__input"
      v-model="value"
      :min="counter.minQuantity"
      :max="counter.maxQuantity"
      ref="input"
      autocomplete="off"
      autocorrect="off"
      @keyup="keyUpHandler"
      @blur="blurHandler"
      tabindex="-1"
    >
    <span
      v-else
      class="counter__input notranslate"
      :class="{
        'counter__input--disabled' : disableInputEdit
      }"
      @click="handleClick"
    >
      <span class="counter__input-text">
        {{ value }}
      </span>
    </span>
    <transition name="vue-animation--spin-fade">
      <quantity-box
        v-if="isQuantityBoxVisible"
        :max-quantity="counter.maxQuantity"
        :min-quantity="counter.minQuantity"
        :current-quantity="value"
        @change="changeQuantity"
        @otherQuantityClick="enableInputField"
        v-outside-click:[true]="toggleQuantityBox"
      />
    </transition>
  </label>
</template>

<script lang="ts">
  import {Vue, Options, Prop, Watch} from 'vue-property-decorator';
  import type Counter from '../../../bps/types/Counter';
  import QuantityBox from '../quantityBox/quantityBox.vue'

  @Options({
    name: 'counter-input',
    components: {
      QuantityBox,
    },
    emit: ['update']
  })
  export default class CounterInput extends Vue {
    @Prop({required: true})
    counter!: Counter;
    @Prop({default: false})
    onlyOneItem!: boolean;
    @Prop({default: false})
    disableInputEdit: boolean;

    isQuantityBoxVisible: boolean = false;
    isInputEnabled: boolean = false;
    isTouch: boolean = null;
    value: any = !this.onlyOneItem ? this.counter.preselectedQuantity : 1;
    inputType: 'number'|'text' = null;
    focusValue: number;

    created() {
      this.isTouch = 'ontouchstart' in window;
      this.inputType = this.isTouch ? 'number' : 'text';
    }

    @Watch('counter.preselectedQuantity')
    onCounterChange() {
      this.value = this.counter.preselectedQuantity;
    }

    handleClick() {
      if (!this.disableInputEdit) {
        this.toggleQuantityBox();
      }
    }

    toggleQuantityBox() {
      this.isQuantityBoxVisible = !this.isQuantityBoxVisible;
    }

    changeQuantity(quantity: number) {
      this.value = quantity;
      this.$emit('update', quantity);
      this.isQuantityBoxVisible = false;
    }

    enableInputField() {
      this.isInputEnabled = true;
      this.isQuantityBoxVisible = false;
      this.focusValue = this.value;
      this.value = '';
      this.$nextTick(() => {
        const input: HTMLInputElement = <HTMLInputElement> this.$refs.input;
        input.focus();
      });
    }

    keyUpHandler(event) {
      if (event.key === 'Enter') {
        const input: HTMLInputElement = <HTMLInputElement> this.$refs.input;
        event.preventDefault();
        input.blur();
      }
    }

    blurHandler() {
      this.isInputEnabled = false;

      if (this.value === '') {
        this.value = this.focusValue && this.focusValue >= this.counter.minQuantity ? this.focusValue : this.counter.minQuantity;
      } else {
        if (typeof this.value === 'string') {
          this.value = this.value.replace(/[^0-9]/g, '');
          this.value = Number(this.value);
        }

        this.value = Math.max(this.value, this.counter.minQuantity);
        this.value = Math.min(this.value, this.counter.maxQuantity);
      }
      this.$emit('update', this.value);
    }

    setValue(value: number) {
      this.value = value;
    }
  }
</script>
