<template>
  <ul
    class="quantity-box counter__quantity-box"
  >
    <li
      v-for="quantity in availableQuantities"
      :key="quantity"
      class="quantity-box__item"
      :class="{
        'quantity-box__item--current' : quantity === currentQuantity,
        'quantity-box__item--disabled' : !isQuantityEnabled(quantity),
      }"
      @click="handleQuantityClick(quantity)"
    >
      {{ quantity }}
    </li>
    <li
      class="quantity-box__item quantity-box__item--other"
      @click="$emit('otherQuantityClick')"
    >
      {{ $t('quantityBox.other') }}
    </li>
  </ul>
</template>

<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';

  @Options({
    name: 'quantity-box',
  })
  export default class QuantityBox extends Vue {
    @Prop()
    maxQuantity: number;
    @Prop()
    minQuantity: number;
    @Prop()
    currentQuantity: number;

    readonly availableQuantities: Array<number> = [1, 2, 3, 4, 6, 12, 18, 24];

    isQuantityEnabled(quantity: number) {
      return quantity <= this.maxQuantity && quantity >= this.minQuantity;
    }

    handleQuantityClick(quantity: number) {
      if (this.isQuantityEnabled(quantity)) {
        this.$emit('change', quantity);
      }
    }
  }
</script>
